define("discourse/plugins/discourse-fingerprint/discourse/templates/fingerprint-report", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="dashboard-next dashboard-fingerprint">
    <div class="section">
      <div class="section">
        <div class="section-title">
          <h2>{{i18n "fingerprint.title"}}</h2>
        </div>
  
        <div class="section-body">
          {{email-group-user-chooser
            value=this.username
            onChange=(action "updateUsername")
            options=(hash maximum=1 filterPlaceholder="user.username.title")
          }}
        </div>
      </div>
    </div>
  
    {{#if this.showReport}}
      {{fingerprint-user-report
        user=this.user
        users=this.users
        fingerprints=this.fingerprints
        ignore=(action "ignore")
        flag=(action "flag")
        showFingerprintData=(action "showFingerprintData")
      }}
    {{else}}
      {{fingerprint-latest-matches
        fingerprints=this.fingerprints
        viewReportForUser=(action "viewReportForUser")
        flag=(action "flag")
        showFingerprintData=(action "showFingerprintData")
      }}
  
      {{fingerprint-flagged
        flagged=this.flagged
        flag=(action "flag")
        showFingerprintData=(action "showFingerprintData")
      }}
    {{/if}}
  </div>
  */
  {
    "id": "cJxfYKWr",
    "block": "[[[10,0],[14,0,\"dashboard-next dashboard-fingerprint\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"fingerprint.title\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"section-body\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"value\",\"onChange\",\"options\"],[[30,0,[\"username\"]],[28,[37,2],[[30,0],\"updateUsername\"],null],[28,[37,3],null,[[\"maximum\",\"filterPlaceholder\"],[1,\"user.username.title\"]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showReport\"]],[[[1,\"    \"],[1,[28,[35,5],null,[[\"user\",\"users\",\"fingerprints\",\"ignore\",\"flag\",\"showFingerprintData\"],[[30,0,[\"user\"]],[30,0,[\"users\"]],[30,0,[\"fingerprints\"]],[28,[37,2],[[30,0],\"ignore\"],null],[28,[37,2],[[30,0],\"flag\"],null],[28,[37,2],[[30,0],\"showFingerprintData\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"fingerprints\",\"viewReportForUser\",\"flag\",\"showFingerprintData\"],[[30,0,[\"fingerprints\"]],[28,[37,2],[[30,0],\"viewReportForUser\"],null],[28,[37,2],[[30,0],\"flag\"],null],[28,[37,2],[[30,0],\"showFingerprintData\"],null]]]]],[1,\"\\n\\n    \"],[1,[28,[35,7],null,[[\"flagged\",\"flag\",\"showFingerprintData\"],[[30,0,[\"flagged\"]],[28,[37,2],[[30,0],\"flag\"],null],[28,[37,2],[[30,0],\"showFingerprintData\"],null]]]]],[1,\"\\n\"]],[]]],[13]],[],false,[\"i18n\",\"email-group-user-chooser\",\"action\",\"hash\",\"if\",\"fingerprint-user-report\",\"fingerprint-latest-matches\",\"fingerprint-flagged\"]]",
    "moduleName": "discourse/plugins/discourse-fingerprint/discourse/templates/fingerprint-report.hbs",
    "isStrictMode": false
  });
});